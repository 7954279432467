<template>
  <div class="vBlockConversionStatistics">
    <vLightPreloader
      v-show="isLoading"
      :fixed="false"
      :transparent="true"
    />
    <div
      class="vBlockConversionStatistics__period"
      :class="{ noIndent: selectedNumberDays > 30 }"
    >
      <div class="vBlockConversionStatistics__period-label">
        <div
          v-if="!period"
          class="vBlockConversionStatistics__period-placeholder"
        >
          Выберите дату или диапазон дат
        </div>
        <div
          v-else
          class="vBlockConversionStatistics__period-placeholder"
          :class="{ active: period }"
        >
          Дата
        </div>

        <div class="vBlockConversionStatistics__period-value">
          {{ period ? convertUTC : '' }}
        </div>
        <VIconCalendar :period="period" />
      </div>
      <date-picker
        v-model:value="period"
        popup-class="calendarWidth"
        :disabled-date="disabledAfterToday"
        range
        @closed="blur"
      />
    </div>

    <div
      v-if="selectedNumberDays > 30"
      class="vBlockConversionStatistics__period-exceeded"
    >
      Выберите период, который будет менее 31 дня
    </div>

    <vButton
      :disabled="!period || selectedNumberDays > 30"
      @click="uploadTable"
    >
      Выгрузить таблицу
    </vButton>

    <vButton
      v-if="period"
      theme="white"
      @click="onReset"
    >
      Сбросить
    </vButton>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'
import vLightPreloader from '@/components/v-light-preloader'
import vButton from '@/components/v-button'
import VIconCalendar from '@/assets/images/iconAdmin/v-icon-calendar'
export default {
  name: 'VBlockConversionStatistics',
  components: {
    DatePicker,
    vButton,
    VIconCalendar,
    vLightPreloader,
  },
  data() {
    return {
      period: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    convertUTC() {
      if (this.period) {
        let periodFrom = this.period[0].toLocaleString().split(',')
        periodFrom = periodFrom[0]

        let periodTo = this.period[1].toLocaleString().split(',')
        periodTo = periodTo[0]

        return periodFrom + ' - ' + periodTo
      }
      return ''
    },

    selectedNumberDays() {
      if (this.period) {
        const dayOne = new Date(this.period[0])
        const dayTwo = new Date(this.period[1])
        const finalDay = Math.ceil(
          Math.abs(dayTwo.getTime() - dayOne.getTime()) / (1000 * 3600 * 24)
        )
        return finalDay
      }
      return ''
    },
  },
  mounted() {
    const body = document.querySelector('body')
    body.classList.add('calendarPage')
  },

  unmounted() {
    const body = document.querySelector('body')
    body.classList.remove('calendarPage')
  },
  methods: {
    ...mapActions('statistic', ['EXPORT_CONVERSION_STATISTICS']),

    onReset() {
      this.period = null
    },

    disabledAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today < date
    },

    uploadTable() {
      this.isLoading = true
      const d = this.period[0],
        month =
          d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1,
        day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate(),
        year = d.getFullYear()
      const newDate = `${year}-${month}-${day}`

      const d2 = this.period[1],
        month2 =
          d2.getMonth() + 1 < 10
            ? '0' + (d2.getMonth() + 1)
            : d2.getMonth() + 1,
        day2 = d2.getDate() < 10 ? '0' + d2.getDate() : d2.getDate(),
        year2 = d2.getFullYear()
      const newDate2 = `${year2}-${month2}-${day2}`

      const periodStatistics = {
        token: this.GET_AUTHENTIFICATED,
        from: newDate,
        before: newDate2,
      }

      this.EXPORT_CONVERSION_STATISTICS(periodStatistics)

      setTimeout(() => {
        this.period = null
        this.isLoading = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.vBlockConversionStatistics {
  &__period {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    margin-bottom: 20px;
    width: 100%;
    height: 52px;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 769px) {
      margin-top: 16px;
    }
    &-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 12px;
      font-size: 16px;
      cursor: pointer;
      color: #010d35;
    }
    &-placeholder {
      color: #80869a;
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 420px) {
        font-size: 12px;
      }
    }
    &-value {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #010d35;
      transform: translateY(45%);
    }
    .active {
      top: 8px;
      font-size: 12px;
      transform: translateY(0);
    }
  }
  &__period-exceeded {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ec4e4e;
    margin: 4px 0 16px 0;
  }
  .noIndent {
    margin: 0;
  }
  .calendarWidth {
    width: 313px !important;
  }
  .mx-datepicker.mx-datepicker-range {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    input {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss">
.calendarPage {
  .mx-calendar-range .mx-calendar:last-child {
    display: none;
  }
  .mx-datepicker-main.mx-datepicker-popup {
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    width: 375px;
    box-shadow: none;
  }
  .mx-btn.mx-btn-text.mx-btn-current-year,
  .mx-btn.mx-btn-text.mx-btn-icon-double-right,
  .mx-btn.mx-btn-text.mx-btn-icon-double-left {
    display: block !important;
  }
  .mx-calendar.mx-calendar-panel-date {
    width: 100%;
  }
  .mx-calendar-header-label {
    button {
      color: #010d35;
      font-weight: 600;
      font-family: 'Commissioner';
      font-size: 20px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .mx-table th {
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 15px;
    font-weight: 400;
  }
  .mx-calendar-content .cell {
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    width: 47px;
    height: 36px;
  }
  .mx-table-date .cell.not-current-month {
    color: #80869a;
    font-family: 'Commissioner';
    font-size: 16px;
    font-weight: 500;
  }
  .mx-calendar-content .cell:hover,
  .mx-calendar-content .cell.active,
  .mx-calendar-content .cell.in-range,
  .mx-calendar-content .cell.hover-in-range {
    background: #f8f9fe;
    color: #6764ff;
  }
  .mx-calendar-content .cell.in-range,
  .mx-calendar-content .cell.hover-in-range {
    border-radius: 0;
  }
  .mx-calendar-content .cell.active {
    border-radius: 12px 0 0 12px;
  }
  .cell.not-current-month.disabled:hover {
    background: none;
    color: #80869a;
  }
  .mx-calendar-content {
    height: auto;
  }
  .cell.in-range ~ .cell.active {
    border-radius: 0 12px 12px 0 !important;
  }
  .mx-calendar-content .cell.disabled {
    color: #ccc !important;
    background: none;
    opacity: 0.5;
  }
  .mx-calendar-content .cell.disabled:hover {
    color: #ccc;
  }
}
</style>
