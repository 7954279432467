<template>
  <section class="vSectionAnalytics section-profile">
    <h2 class="section-profile__title">
      Аналитика
    </h2>
    <div class="vSectionAnalytics__content">
      <div class="vSectionAnalytics__switching">
        <button
          class="vSectionAnalytics__switching-tabs"
          :class="{ tabs__active: selectionOptions === 'conversionStatistics' }"
        >
          Статистика по конверсиям
        </button>
      </div>
      <div class="wide-line" />

      <VBlockConversionStatistics
        v-if="selectionOptions === 'conversionStatistics'"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import VBlockConversionStatistics from '@/components/profile/v-block-conversion-statistics'
export default {
  name: 'VSectionAnalytics',
  components: {
    VBlockConversionStatistics,
  },
  data() {
    return {
      selectionOptions: 'conversionStatistics',
    }
  },
  mounted() {
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
  },
}
</script>

<style lang="scss">
.vSectionAnalytics {
  &__content {
    background-color: #fff;
    border-radius: 11px;
    border: 1px solid #e6e7eb;
    padding: 24px 24px 8px 24px;
    max-width: 636px;
    h3 {
      margin-bottom: 24px;
    }
  }
  &__switching {
    width: 100%;
    background: #f7f8fa;
    border-radius: 14px;
    height: 48px;
    padding: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &-tabs {
      color: #80869a;
      padding: 12px 17px;
      border-radius: 11px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      border: none;
      background: #f7f8fa;
      cursor: pointer;
    }
  }
  .tabs__active {
    background: #fff;
    color: #010d35;
  }
  .wide-line {
    width: 100%;
    border-top: 4px solid #f7f8fa;
    margin-bottom: 16px;
  }
}
</style>
