<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="calendar"
    :class="{ active: period }"
  >
    <path
      d="M1 7.5H19M5.5 1V4V1ZM14.5 1V4V1Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 2.5H4C2.34315 2.5 1 3.84315 1 5.5V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V5.5C19 3.84315 17.6569 2.5 16 2.5Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconCalendar',
  props: {
    period: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.calendar {
  stroke: #80869a;
}
.active {
  stroke: #6764ff !important;
  fill: none !important;
}
</style>
